import { useLocation, useMatches } from "@remix-run/react";
import { useNavigationType } from "@remix-run/react";
import { useEffect } from "react";
import * as Sentry from "@sentry/remix";

// console.debug("Sentry client: ", import.meta.env.MODE);

Sentry.init({
  environment: import.meta.env.MODE,
  dsn: "https://7e9bd1acd842474e93ebd8377f8035b2:21dcc4722fda4ea4a74b03ff72d1074a@o4505311880937472.ingest.sentry.io/4505317126504448",
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
});

// Rest of the code - Must be after Sentry.init (v8 documentation)
import { hydrateRoot } from "react-dom/client";
import { RemixBrowser } from "@remix-run/react";
import { startTransition } from "react";

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(document, <RemixBrowser />);
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
